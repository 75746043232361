exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-cat-year-archive-js": () => import("./../../../src/templates/CatYearArchive.js" /* webpackChunkName: "component---src-templates-cat-year-archive-js" */),
  "component---src-templates-category-archive-js": () => import("./../../../src/templates/CategoryArchive.js" /* webpackChunkName: "component---src-templates-category-archive-js" */),
  "component---src-templates-work-archive-js": () => import("./../../../src/templates/WorkArchive.js" /* webpackChunkName: "component---src-templates-work-archive-js" */),
  "component---src-templates-work-template-js": () => import("./../../../src/templates/WorkTemplate.js" /* webpackChunkName: "component---src-templates-work-template-js" */),
  "component---src-templates-year-archive-js": () => import("./../../../src/templates/YearArchive.js" /* webpackChunkName: "component---src-templates-year-archive-js" */)
}

